.ms-Grid {
    .ms-Grid-row {
        margin: 0 -8px;
    }

    .ms-Grid-col {
        margin-bottom: 6px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.ms-DetailsRow-fields {
    align-items: center;
}

.ms-DetailsRow-cell {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
}

.ms-Nav-link {
    color: rgb(50, 49, 48);
}

.ms-Button--commandBar.color-red:hover {
    color: red;

    i {
        color: red;
    }
}

.ms-Label {
    padding: 0px;
}

.Toastify__toast {
    border-radius: 2px;
    z-index: 10000000000;
}

.matrix-page {

    .ms-DetailsRow-cell[data-automation-key="action"],
    .ms-DetailsHeader-cell[data-item-key="action"] {
        position: sticky;
        left: 0;
        z-index: 9;
        background: #fff;
    }

    .ms-DetailsRow-cell[data-automation-key="Product.Name"],
    .ms-DetailsHeader-cell[data-item-key="Product.Name"] {
        position: sticky;
        left: 40px;
        z-index: 9;
        background: #fff;
        border-right: 1px solid rgb(243, 242, 241);
    }

    .ms-DetailsRow {
        &:hover {
            .ms-DetailsRow-cell {
                background: rgb(243, 242, 241);
                color: rgb(50, 49, 48);
            }
        }
    }

    .ms-DetailsHeader {
        .ms-DetailsHeader-cell {
            &:hover {
                background: rgb(243, 242, 241);
                color: rgb(50, 49, 48);
            }
        }
    }
}

.item {
    .onHover {
        visibility: hidden;
    }

    &:hover {
        .onHover {
            visibility: visible;
        }
    }
}

.combieControl {
    .ms-SearchBox {
        border-left: none;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;

        &::after {
            border: none !important;
        }
    }

    .ms-Dropdown-title {
        //border-right: none;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    .ms-Dropdown,
    .dropdown-237 {
        &::after {
            border: none !important;
        }
    }
}

.list_notification {
    height: calc(100vh - 150px);
    display: grid;
}