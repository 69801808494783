$background_color: #faf9f8;

html,
body,
#root {
  height: 100%;
  margin: 0;
  line-height: 20px;
  background-color: $background_color;
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto,
    'Helvetica Neue', sans-serif;
}
.main-app {
  min-height: 100%;
  height: 100%;
  min-width: 1024px;
}
.content {
  height: calc(100% - 62px);
  display: flex;
}

.main-container {
  height: 100%;
  overflow: auto;
  width: calc(100% - 250px);
}

.page-content {
  padding: 20px;
  background: #fff;
  margin: 20px;
  position: relative;

  .header-action {
    height: 32px;
  }
}

.width100 {
  width: 100%;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-auto {
  margin: auto;
}

.margin-0 {
  margin: 0 !important;
}

.margin-right-auto {
  margin-right: auto;
}

.margin-left-0 {
  margin-left: 0px;
}

.margin-right-0 {
  margin-right: 0px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-bottom {
  margin-bottom: 12px !important;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-right-0 {
  padding-right: 0;
}

.display-flex {
  display: flex;
}

.flex-center {
  display: flex;
  align-items: center;
}

.page-header {
  .ms-Label {
    font-size: 18px;
    padding: 0;
  }

  line-height: 45px;
  background: #fff;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.OnGoing {
  color: #d9b00d;
}
.Pending {
  color: rgb(0, 74, 98);
}
.Expired {
  color: red;
}

.Approved {
  color: #0aa252;
}

.color-red {
  color: red;
}

.flex-column {
  flex-direction: column;
}

.flex-justify-content-between {
  justify-content: space-between;
  align-items: center;
}

.margin-top {
  margin-top: 12px;
}

.padding {
  padding: 12px;
}

.padding-lr {
  padding: 0 12px;
}

.padding-right {
  padding-right: 12px;
}

.light-background {
  background-color: #faf9f8 !important;
}

.message-row {
  border-bottom: 1px solid #faf9f8;
  padding: 20px 0;

  .avatar {
    img {
      border-radius: 50%;
    }
  }
}

.message-content {
  width: 100%;
}

.message-detail {
  margin-top: 5px;
  white-space: pre-line;
}

.sub-title {
  color: gray;
  font-size: 11px;
  line-height: 11px;
}

.component-test {
  border: 1px dashed #e1dfdd;
  text-align: center;
}

.matrix-detail {
  .ms-Grid {
    margin: 0 -8px;
    label {
      width: 300px;
    }
  }
}

.group-textarea {
  border: 1px solid rgb(96, 94, 92);

  .ms-TextField-fieldGroup {
    border: none;
  }
}

a {
  cursor: pointer;
}

.chartBox {
  width: 24px;
  height: 24px;
  overflow: hidden;
}

hr {
  border-top: 1px solid #e1dfdd;
  border-left: none;
}

.OverDue {
  color: red;
}

.loading-full {
  z-index: 1;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding-top: 40px;
  z-index: 100000000;
}

.center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  color: darkred;
}
.line-height-24 {
  line-height: 24px;
}

:root {
  --toastify-color-success: #028980 !important;
  --toastify-color-warning: #f1c40f !important;
  --toastify-color-error: #b22509 !important;
}
.ms-Spinner--large {
  border-width: 4.5px;
  width: 52px;
  height: 52px;
}

.notiIcon {
  position: absolute;
  color: red;
  right: 11px;
  font-size: 20px;
}

.iconTab {
  margin-right: -14px;
}
