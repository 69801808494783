.ms-Modal {
    .modal-content {
        padding: 12px 22px 22px 22px;
    }

    .modal-footer {
        text-align: right;
        padding: 0 22px 22px 22px;
    }
}
.ms-Modal-Layer[data-portal-element="true"] {
    position: absolute;
    height: 100%;
}

.ms-Modal-Layer .ms-Modal.is-open {
    background: #00000045;
    overflow: hidden;
    .ms-Modal-scrollableContent{
        overflow: hidden;
         max-height: 100%;
    }
}